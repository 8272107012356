
.light {
  background-color: #c4d3f2;
}

.dark {
  background-color: #5a5688;
}

.highlight {
  background-color: #d5d269;
}
